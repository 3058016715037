var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column flex-root" }, [
    _c(
      "div",
      {
        staticClass:
          "login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white",
        attrs: { id: "kt_login" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15",
            style: { backgroundImage: `url(${_vm.backgroundImage})` },
          },
          [_vm._m(0)]
        ),
        _c(
          "div",
          {
            staticClass:
              "flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column-fluid flex-center mt-30 mt-lg-0",
              },
              [_c("router-view")],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-row-fluid flex-column justify-content-between",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex-column-fluid d-flex flex-column justify-content-center",
          },
          [
            _c(
              "h3",
              {
                staticClass: "font-size-h1 mt-10 mb-5 text-white welcome-text",
              },
              [_vm._v(" Welcome to Astrologyzone Mobile CMS! ")]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }